<template>
  <v-app>
      <Nuxt class="content" />
  </v-app>
</template>

<script>
export default {

}
</script>

<style>

</style>